<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" style="min-height: 78vh;">
      <div class="col-12" v-if="dataReady === true && inserting === false">
        <div class="card h-100">
          <div class="card-body">
            <div class="row justify-content-end">
              <div class="col-md-6 text-end">
                <div class="mb-3">
                  <a href="javascript:void(0);" class="btn btn-primary"   @click="insertStore()"
                    ><i class="mdi mdi-plus me-2"></i> Aggiungi azienda
                  </a>
                </div>
              </div>
            </div> 
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6 col-lg-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Visualizza  &nbsp; 
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; risultati per pagina
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6 col-lg-8">
                <div
                  id="tickets-table_filter  "
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center w-100">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Cerca..."
                      class="form-control rounded bg-light border-0 ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="table table-centered table-nowrap"
                :items="storesList"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(azioni)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="px-2"
                            v-b-tooltip.hover
                            title="Dettagli"
                            style="color:#0071DC"
                            @click.prevent="viewStore(data.item.id)"
                        >
                            <i class="uil uil-eye font-size-18"></i>
                        </a>
                    </li>
                    <!-- <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="px-2"
                            v-b-tooltip.hover
                            title="Modifica"
                            style="color:#f1b44c"
                            @click.prevent="editStore(data.item.id)"
                        >
                            <i class="uil-pen font-size-18"></i>
                        </a>
                    </li> -->
                    <!-- <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="px-2"
                            v-b-tooltip.hover
                            title="Elimina"
                            style="color:#f46a6a"
                            @click.prevent="deleteStore(data.item.id)"
                        >
                            <i class="uil-trash font-size-18"></i>
                        </a>
                    </li> -->
                  </ul>
                </template>
                <template v-slot:cell(Account)="data">
                  <span class="badge bg-success" v-if="data.item.account.is_active">Attivo</span>
                  <span class="badge bg-warning" v-else>Non attivo</span>
                </template>
                <template v-slot:cell(licenza)="data">
                    <span class="badge bg-success" v-if="data.item.license !== null">Attiva</span>
                    <span class="badge bg-danger" v-else>Non attiva</span>
                </template>
                <template v-slot:cell(condizioni-generali)="data">
                    <span class="badge bg-success" v-if="data.item.license_terms_accepted === true">Accettate</span>
                    <span class="badge bg-danger" v-else>In sospeso</span>
                </template>
                <template v-slot:cell(informativa-privacy)="data">
                    <span class="badge bg-success" v-if="data.item.privacy_terms_accepted === true">Accettata</span>
                    <span class="badge bg-danger" v-else>In sospeso</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            
            <!-- Insert/Edit/View modal-->
            <b-modal
              v-model="insertModal"
              title-class="text-black font-18"
              size="lg"
              hide-footer
              body-class="p-3"
            >
              <div slot="modal-title">
                <i class="uil-building font-size-18"></i> Nuova azienda</div>
                <form @submit.prevent="handleSubmit">
                  <div class="row">

                    <!-- Company data -->
                    <h5 class="mb-4">Dati aziendali</h5>
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="company_name" class="form-label">Ragione Sociale</label>
                        <input
                          id="company_name"
                          v-model="currentStore.company_name"
                          type="text"
                          value=""
                          :disabled="edit === true || view === true"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && this.$v.currentStore.company_name.$error }"
                          placeholder="Ragione sociale dell'azienda"
                        />
                        <div 
                            v-if="submitted && this.$v.currentStore.company_name.$error" 
                            class="col-12 invalid-feedback d-block"
                        >
                            <span 
                                v-if="!this.$v.currentStore.company_name.required">
                                    Campo obbligatorio
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label for="company_id" class="control-label form-label">C.F./P.IVA</label>
                        <input
                            id="company_id"
                            v-model="currentStore.company_id"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.company_id.$error }"
                            :disabled="edit === true || view === true"
                            placeholder="Inserisci il Codice Fiscale o la P.IVA"
                          />
                          <div 
                            v-if="this.submitted && this.$v.currentStore.company_id.$error"  
                            class="col-12 invalid-feedback d-block"
                          >
                            <span 
                                v-if="!this.$v.currentStore.company_id.required"
                            >
                                Campo obbligatorio
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label for="company_region" class="control-label form-label">Regione</label>
                        <input
                            id="company_region"
                            v-model="currentStore.company_region"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.company_region.$error }"
                            :disabled="edit === true || view === true"
                            placeholder="Regione"
                          />
                          <div 
                            v-if="this.submitted && this.$v.currentStore.company_region.$error"  
                            class="col-12 invalid-feedback d-block"
                          >
                            <span 
                                v-if="!this.$v.currentStore.company_region.required"
                            >
                                Campo obbligatorio
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label for="company_province" class="control-label form-label">Provincia</label>
                        <input
                            id="company_province"
                            v-model="currentStore.company_province"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.company_province.$error }"
                            :disabled="edit === true || view === true"
                            placeholder="Regione"
                          />
                          <div 
                            v-if="this.submitted && this.$v.currentStore.company_province.$error"  
                            class="col-12 invalid-feedback d-block"
                          >
                            <span 
                                v-if="!this.$v.currentStore.company_province.required"
                            >
                                Campo obbligatorio
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label for="company_city" class="control-label form-label">Città</label>
                        <input
                            id="company_city"
                            v-model="currentStore.company_city"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.company_city.$error }"
                            :disabled="edit === true || view === true"
                            placeholder="Città"
                          />
                          <div 
                            v-if="this.submitted && this.$v.currentStore.company_city.$error"  
                            class="col-12 invalid-feedback d-block"
                          >
                            <span 
                                v-if="!this.$v.currentStore.company_city.required"
                            >
                                Campo obbligatorio
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="company_address" class="control-label form-label">Indirizzo</label>
                        <input
                            id="company_address"
                            v-model="currentStore.company_address"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.company_address.$error }"
                            :disabled="edit === true || view === true"
                            placeholder="Indirizzo"
                          />
                          <div 
                            v-if="this.submitted && this.$v.currentStore.company_address.$error"  
                            class="col-12 invalid-feedback d-block"
                          >
                            <span 
                                v-if="!this.$v.currentStore.company_address.required"
                            >
                                Campo obbligatorio
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label for="phone" class="control-label form-label">Telefono</label>
                        <input
                            id="phone"
                            v-model="currentStore.phone"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.phone.$error }"
                            :disabled="edit === true || view === true"
                            placeholder="Inserisci un recapito telefonico"
                        />
                        <div v-if="submitted && this.$v.currentStore.phone.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentStore.phone.numeric">Formato non valido</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label for="pec" class="control-label form-label">Indirizzo PEC</label>
                        <input
                            id="pec"
                            v-model="currentStore.pec"
                            type="text"
                            class="form-control"
                            :disabled="edit === true || view === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.pec.$error }"
                            placeholder="Inserisci l'indirizzo PEC"
                        />
                          <div v-if="submitted && this.$v.currentStore.pec.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentStore.pec.email">Indirizzo e-mail non valido</span>                      
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <!-- License data -->
                    <h5 class="mb-4">Dati Licenza</h5>
                    <div class="col-6">
                        <div class="mb-3">
                            <label for="license" class="control-label form-label">Tipologia:</label>
                            <select name="license" class="form-select" v-model="currentStore.license">
                                <option value="" selected="true">Non attivare</option>
                                <option v-for="l in licensesList" :key="l.id" :value="l.id">{{ l.name }} ({{ l.duration_days }} giorni)</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label for="license_activation_date" class="control-label form-label">Data di attivazione:</label>
                            <input
                                id="license_activation_date"
                                v-model="currentStore.license_activation_date"
                                type="date"
                                class="form-control"
                                :disabled="!currentStore.license"

                            />
                            <!-- <div v-if="submitted && this.$v.currentStore.license_activation_date.$error"  class="col-12 invalid-feedback d-block">
                                <span v-if="!this.$v.currentStore.license_activation_date.required">Campo obbligatorio</span>                      
                            </div> -->
                        </div>
                    </div>
                    <hr/>
                    <!-- Reference person data -->
                    <h5 class="mb-4">Referente</h5>
                    <div class="col-6">
                      <div class="mb-3">
                        <label class="control-label form-label">Nome</label>
                        <input
                            id="phone"
                            v-model="currentStore.account_first_name"
                            type="text"
                            class="form-control"
                            :disabled="edit === true || view === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.account_first_name.$error }"
                            placeholder="Nome del referente"
                        />
                          <div v-if="submitted && this.$v.currentStore.account_first_name.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentStore.account_first_name.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 align-self-end">
                      <div class="mb-3 align-self-end">
                        <label class="control-label form-label">Cognome</label>
                        <input
                            id="phone"
                            v-model="currentStore.account_last_name"
                            type="text"
                            class="form-control"
                            :disabled="edit === true || view === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.account_last_name.$error }"
                            placeholder="Cognome del referente"
                        />
                          <div v-if="submitted && this.$v.currentStore.account_last_name.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentStore.account_last_name.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <!-- Account data -->
                    <h5 class="mb-4">Account</h5>
                    <!-- <div class="alert alert-warning w-75">
                         Questa password è solo <b>temporanea</b>. Il cliente dovrà modificarla al primo accesso
                      </div> -->
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="account_email" class="control-label form-label">Indirizzo e-mail</label>
                        <input
                            id="account_email"
                            v-model="currentStore.account_email"
                            type="text"
                            class="form-control"
                            :disabled="edit === true || view === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.account_email.$error }"
                            placeholder="Indirizzo e-mail dell'account"
                        />
                          <div v-if="submitted && this.$v.currentStore.account_email.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentStore.account_email.required">Campo obbligatorio</span>
                            <span v-if="!this.$v.currentStore.account_email.email">Indirizzo e-mail non valido</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-6" v-if="edit === false && view === false">
                      <div class="mb-3">
                        <label for="account_password" class="control-label form-label">Password</label>
                        <input
                            id="account_password"
                            v-model="currentStore.account_password"
                            type="text"
                            class="form-control"
                            :disabled="edit === true || view === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentStore.account_password.$error }"
                            placeholder="Password dell'account"
                        />
                        <div v-if="submitted && this.$v.currentStore.account_password.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentStore.account_password.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <!-- Form buttons -->
                  <div class="p-3 form-group w-100 text-center" v-if="edit === false && view === false">
                    <b-button v-if="!edit" type="submit" class="mx-4" variant="success" 
                      >Inserisci</b-button
                    >
                    <b-button variant="danger" @click="closeModal">Annulla</b-button>
                  </div>
                </form>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="col-12 text-center align-self-center" v-else-if="dataReady === false || inserting === true">
          <div class="lds-dual-ring"></div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
// import appConfig from "@/app.config";
import { 
    required, 
    email,
    numeric,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import API from "@/api.js";


/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Elenco aziende",
    // meta: [
    //   {
    //     name: "description",
    //     content: appConfig.description,
    //   },
    // ],
  },
  data() {
    return {
      title: "Elenco aziende",
      items: [
        {
          text: "Amministrazione",
        },
        {
          text: "Elenco aziende",
          active: true,
        },
      ],
      dataReady: false,
      inserting: false,
      storesList: [],
      licensesList: [],
      curId:undefined,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],

      filter: null,
      filterOn: [],
      sortBy: "company_name",
      sortDesc: false,
      fields: [
        {
            key: "company_name",
            label: "Rag. Sociale"
        },
        {
            key: "company_id",
            label: "C.F/P. IVA"
        },
        {
            key:"account.email",
            label: "E-mail"
        },
        // {
        //     key: "phone",
        //     label: "Telefono"
        // },
        // {
        //     key: "company_city",
        //     label: "Città"
        // },
        // {
        //     key: "company_province",
        //     label: "Provincia",
        // },
        "Account",
        "Licenza",
        {
          key: 'condizioni-generali',
          label: "Condizioni Generali",
        },
        {
          key: 'informativa-privacy',
          label: "Informativa Privacy",
        },
        "Azioni",
      ],
       
      edit:true,
      view: false,
      insertModal: false,
      submitted:false,

      currentStore: {
            account_email: "",
            // account_password: "",
            account_first_name: "",
            account_last_name: "",
            company_name: "",
            company_id: "",
            company_country: "",
            company_region: "",
            company_province: "",
            company_city: "",
            company_address: "",
            phone: "",
            pec: "",
            license: "",
            license_activation_date: "01/10/2021",  
        },
    };
  },
  validations: {

    currentStore: {

        account_email: { required, email },
        // account_password: { required },
        account_first_name: { required },
        account_last_name: { required },
        company_name: { required },
        company_id: { required },
        // company_country: { required },
        company_region: { required },
        company_province: { required },
        company_city: { required },
        company_address: { required },
        license: { required },
        license_activation_date: { required },
        phone: { numeric },
        pec: { email },  
    },

    validationInsert: [
        'currentStore.account_email',
        // 'currentStore.account_password',
        'currentStore.account_first_name',
        'currentStore.account_last_name',
        'currentStore.company_name',
        'currentStore.company_id',
        // 'currentStore.company_country',
        'currentStore.company_region',
        'currentStore.company_province',
        'currentStore.company_city',
        'currentStore.company_address',
        'currentStore.phone',
        'currentStore.license',
        'currentStore.license_activation_date',
    ],

  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.storesList.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.getStoresList();
    this.getLicensesList();
  },
  methods: {
    
    responsemsg(msg,type) {
      Swal.fire({
        position: "center",
        icon: type,
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },

    /**
     * Return the list of licenses
     */
    async getStoresList() {
      this.dataReady = false;
      try {
        const r = await this.axios.get(API.getStores.path());
        this.dataReady = true;
        if (r.status === 200) {
          this.storesList = r.data;
        }
      } catch (e) {
        this.storesList = [];
        this.dataReady = true;
      }
      console.log(this.storesList);
      this.totalRows = this.storesList.length;
    },
    
    /**
     * Return the list of stores
     */
    async getLicensesList() {
        try {
            const r = await this.axios.get(API.getLicenses.path());
            if (r.status === 200) {
                this.licensesList = r.data;
            }
            console.log(r);
        } catch (e) {
            this.licensesList = [];
        }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

   
    insertStore() {
      this.currentStore = {};
      this.edit=false;
      this.insertModal = true;
      this.curId=undefined;
    },

    viewStore(id) {
        this.edit = false;
        this.view = true;
        const store= this.storesList.find((s) =>  s.id === id );
        this.currentStore = {
            ...store,
            account_email: store.account.email,
            account_first_name: store.account.first_name,
            account_last_name: store.account.last_name,
        };
        delete this.currentStore.account;
        this.curId = id;
        this.insertModal = true;
    },

    editStore(id) {
        this.edit = true;
        this.view = false;
        this.currentStore = this.storesList.find((s) => { s.id === id });
        const store= this.storesList.find((s) =>  s.id === id );
        this.currentStore = {
            ...store,
            account_email: store.account.email,
            account_first_name: store.account.first_name,
            account_last_name: store.account.last_name,
        };
        delete this.currentStore.account;
        this.curId = id;
        this.insertModal = true;
    },

    deleteStore() {

    },


   async handleSubmit(){
      
      this.submitted=true;
      this.inserting = true;
      this.$v.$touch();
    //   console.log(this.currentStore);
    //   console.log("TYry me",this.$v.validationInsert.$invalid);
      if (this.$v.validationInsert.$invalid) {
        return;
      } else {
        const objStore = {
            account: {
                email: this.currentStore.account_email,
                password: "randomGen1!",
                first_name: this.currentStore.account_first_name,
                last_name: this.currentStore.account_last_name
            },
            company_name: this.currentStore.company_name,
            company_id: this.currentStore.company_id,
            company_country: "Italia",
            company_region: this.currentStore.company_region,
            company_province: this.currentStore.company_province,
            company_city: this.currentStore.company_city,
            company_address: this.currentStore.company_address,
            phone: this.currentStore.phone,
            pec: this.currentStore.pec,
            license: this.currentStore.license,
            license_activation_date: this.currentStore.license_activation_date,  
        };
        this.closeModal();
        try {
            const r = await this.axios.post(API.insertStore.path(),objStore);
            console.log(r);
            if (r.status === 201) {
                this.responsemsg("Nuova azienda aggiunta", "success");
                
            } else {
                this.responsemsg("Operazione fallita"+r.data, "error");
            }
        } catch (e) {
            this.responsemsg("Operazione fallita"+e, "error");
        }
        // console.log(objStore);
      }
      this.inserting = false;
      this.reloadList();

    },

    async reloadList(){
        this.getStoresList();
        // console.log(this.items);
        this.totalRows = this.storesList.length;
    },


    closeModal() {
      this.insertModal = false;
      this.edit = false;
      this.view = false;
      this.submitted=false;
      this.deleteModal=false;
      this.sellingModal = false;
      this.$v.$reset();
    },
    
  },
  middleware: "authentication",
};
</script>
